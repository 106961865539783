import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import westLogo from "assets/images/fitfarm-omaha-west-location-logo.svg"
import midLogo from "assets/images/fitfarm-omaha-midtown-location-logo.svg"
const HomeOmahaGyms = (props) => {
  return (
    <>
      <BackgroundImage
        Tag="section"
        fluid={props.sectionImage.childImageSharp.fluid}
        className="banner-location"
      >
        <div className="u-center-text" style={{ paddingTop: "5rem" }}>
          <h2 className="type-header__locations type-emph type-white">
            CrossFit <span className="type-heavy">Gyms</span>
          </h2>
        </div>
        <div className="grid-location u-mt-2">
          <div className="grid-location__col ">
            <img
              src={midLogo}
              alt="Fitfarm Members sitting outside smiling togther"
              className="img-location"
            />
            <p className="type-reg type-white type-body u-mt-2">
              <span className="type-heavy type-white">Gym: </span>
              FitFarm Midtown
            </p>
            <p className="type-reg type-white type-body">
              <span className="type-heavy type-white">Address: </span>
              4383 Nicholas St, Omaha, NE 68131
            </p>
            <a
              href="https://app.wodify.com/Schedule/PublicCalendarListView.aspx?tenant=4901"
              className="type type-heavy type-yellow type-h3"
            >
              Join a Class
            </a>
          </div>
        </div>
        <div className="grid-lang">
          <h2 className="type-header__tertiary type-heavy type-white">
            Speak the Language
          </h2>
          <p className="type-reg type-white type-body u-mt-1">
            <span className="type type-heavy type-yellow">WOD: </span>
            Workout of the Day.
          </p>
          <p className="type-reg type-white type-body u-mt-1">
            <span className="type type-heavy type-yellow">AMRAP: </span>
            As Many Round (or Reps) as Possible. Used when the workout is a
            circuit, and you’re supposed to do as many rounds or reps as
            possible.{" "}
          </p>
          <p className="type-reg type-white type-body u-mt-1">
            <span className="type type-heavy type-yellow">EMOM: </span>
            Every Minute on the Minute. Used for High Intensity Interval
            Training.
          </p>
          <p className="type-reg type-white type-body u-mt-1">
            <span className="type type-heavy type-yellow">BOX: </span>
            Another term for gym. When people say, “I’ll see you at the box,”
            they mean the CrossFit gym.
          </p>
          <p className="type-reg type-white type-body u-mt-1">
            <span className="type type-heavy type-yellow">METCON: </span>
            An abbreviation for “Metabolic Conditioning,” a type of training
            that improves endurance.
          </p>
          <p className="type-reg type-white type-body u-mt-1">
            <span className="type type-heavy type-yellow">
              The Whiteboard:{" "}
            </span>
            Where CrossFit gyms write the WOD and athletes’ scores.
          </p>
        </div>
        <div className="u-center-text">
          <Link
            to="/contact-us/"
            className="btn btn-primary btn-primary__gold type-heavy type-black"
          >
            Speak with a Trainer
          </Link>
        </div>
      </BackgroundImage>
    </>
  )
}

export default HomeOmahaGyms
